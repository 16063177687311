<template>
  <v-row>
    <v-col>
      <v-img
        src="https://res.cloudinary.com/jlr-contractors/image/upload/v1656595474/Woodshop/TopBanner-min_tvhxyb.webp"
        min-height="99vh"
        max-height="99vh"
        max-width="100vw"
        class="pb-2 pr-0"
        dark
      >
        <div id="socials" v-if="$vuetify.breakpoint.mdAndUp">
          <p class="ml-n5 body-1">Follow us</p>
          <div class="d-flex flex-column align-start mt-9">
            <v-icon
              v-for="social in $store.state.socialsWoodwork"
              :key="social.i"
              size="30"
              class="my-1"
              @click="openSocial(social.link)"
            >
              {{ social.icon }}
            </v-icon>
          </div>
        </div>
        <hero
          :title="hero.title"
          :titleColored="hero.titleColored"
          :subtitle="hero.subtitle"
        />
        <div id="gradient" />
      </v-img>
      <v-row>
        <v-col class="py-0">
          <Banner
            :imageIndex="'woodwork'"
            imageType="jpg"
            imageHeight="400"
            :image="banner1.image"
            :heading="banner1.heading"
            :subheading="banner1.subheading"
            :description="banner1.description"
            :list="banner1.list"
            :btn="banner1.btn"
            :headHTML="banner1.headHTML"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-0">
          <Quote
            :image="quote.image"
            imageIndex="home_1"
            imageType="jpg"
            :title="quote.title"
            :height="$vuetify.breakpoint.mdAndUp ? '600' : '450'"
            :titleHTML="quote.titleHTML"
            :fix-height="$vuetify.breakpoint.mdAndUp ? true : false"
          />
        </v-col>
      </v-row>
      <v-row class="pt-10">
        <v-col class="pb-0 mb-0 text-center">
          <h2 class="white--text">VIEW OUR WORK</h2>
        </v-col>
      </v-row>
      <v-row
        class="mx-auto"
        :class="$vuetify.breakpoint.mdAndUp ? 'py-10' : 'mt-16'"
      >
        <v-col
          class="py-0"
          v-for="(item, i) in gridItems"
          :key="item.i"
          cols="12"
          sm="6"
          md="3"
        >
          <GridItem
            page="home"
            :index="i"
            :gridItem="item"
            :image="item.image"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <TeamGrid company="JLR Woodwork" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <Companies :slides="slides" />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-0">
          <ImageRow
            image="https://res.cloudinary.com/jlr-contractors/image/upload/v1656595474/Woodshop/BottomBanner-min_tej0zt.webp"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Hero from "@/components/Page/Hero";
import Banner from "@/components/Page/Banner";
import Quote from "@/components/Page/Quote";
import Companies from "@/components/Page/Companies";
import GridItem from "../components/About/GridItem.vue";
import ImageRow from "../components/Page/ImageRow.vue";
import TeamGrid from "../components/About/TeamGrid.vue";
export default {
  name: "Woodwork",

  components: {
    Hero,
    Banner,
    Quote,
    Companies,
    GridItem,
    ImageRow,
    TeamGrid,
  },

  data() {
    return {
      hero: {
        title: "Proudly serving for over",
        titleColored: "37 years",
      },
      banner1: {
        image:
          "https://res.cloudinary.com/jlr-contractors/image/upload/v1656043793/Woodshop/NoSubstitute_vddxdk.webp",
        heading: `There is no substitute for <span class="primary--text">Excellence</span>`,
        headHTML: true,
        description: [
          `<span class="text-center">Our creations receive the utmost of care and attention in our world-class 40,000 square foot woodworking
          facility located in <span class="primary--text">Provo, Utah</span>. As we do on all projects, we spend the necessary time in the early stages
          to minimize cost and create a product that matches the architects/designers concptual drawings while our
          three phases of <span class="primary--text">millwork, assembly, and finish</span>, are aided by the latest in technology.</span>`,
        ],
        btn: {
          text: "Contact Us",
          to: "/contact",
        },
      },
      banners: [
        {
          heading: "Architectural Woodwork",
          image:
            "https://res.cloudinary.com/coberapps/image/upload/v1655177205/JLR/images/jlr_banner_woodwork_e9cbn4.jpg",
          description: [
            `<span class="primary--text">The Passion, Possibiliies and Perfection of Woodworking.</span>`,
            `The woodwork team has engineered, manufactured and installed every type of architectural woodwork
            for over 37 years, leading the industry by providing the latest wood grains to customers while producing
            a world-class product.`,
            `With our 40,000 square foot woodworking facility, located in Provo, UT, we are able to spend the necessary time
            in the early stages to minimize the cost and create a product that matches the architects/designers conceptual
            drawings while our three phases of <span class="primary--text">millwork, assembly, and finish</span>, are aided by the 
            latest in technology. The result? A process that enables perfection; expertise that produces world-class woodwork.`,
          ],
          btn: {
            text: "Contact Us",
            to: "/contact",
          },
          btn2: {
            text: "Woodwork Site",
            to: "/",
          },
          reverse: true,
        },
      ],
      gridItems: [
        {
          title: "RESIDENTIAL / REMODEL",
          link: "/our-work/architectural-woodwork/residential",
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_600/v1656043795/Woodshop/Residential_blkove.webp",
          index: "Residential",
        },
        {
          title: "COMMERCIAL / REMODEL",
          link: "/our-work/architectural-woodwork/commercial",
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_600/v1656043794/Woodshop/Commercial_uhktjt.webp",
          index: "Commercial",
        },
        {
          title: "Hospitality",
          link: "/our-work/architectural-woodwork/hospitality",
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_600/v1656595475/Woodshop/TopBanner4-min_xes0iz.webp",
          index: "Residential",
        },
        {
          title: "Medical / Institutional",
          link: "/our-work/architectural-woodwork/medical-institutional",
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_600/v1656043792/Woodshop/medical_institutional_yozopy.webp",
          index: "Residential",
        },
      ],
      quote: {
        image:
          "https://res.cloudinary.com/jlr-contractors/image/upload/v1656595475/Woodshop/MiddleBanner-min_d0usom.webp",
        title: `Unify <span class="primary--text mx-6">Strengthen</span> Grow`,
        titleHTML: true,
      },
      slides: [
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1657372580/Logos/church-of-jesus-christ-of-latter-day-saints-logo_zvovvu.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1655813720/Logos/logo-snowbird-min_p90au1.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1655813720/Logos/NicePng_walt-disney-logo-png_2235742-min_fdxykx.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1655813720/Logos/alpha-logo-on-white-trans_vvelmx.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1655813720/Logos/pngwing.com-min_avbrc7.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1655813719/Logos/Charles_Schwab_Corporation-Logo.wine-min_oyi3ib.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1655813719/Logos/images-min_nryovs.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1655813719/Logos/Daco_5029191-min_knuxnv.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1657977180/Logos/Woodwork/Wells-Fargo-Logo-2009-min_gmb9jo.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1657977180/Logos/Woodwork/rubys-logo-tourist-rest-med-min_sxtpvx.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1657977180/Logos/Woodwork/PrZnM4C1-min_rl6yih.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1657977180/Logos/Woodwork/mountain-america-credit-union-logo-freelogovectors-min_vfs5md.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1657977179/Logos/Woodwork/Marriott-International-Logo-1993-2016-min_ptbonl.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1657977179/Logos/Woodwork/logo_full-x5-min_p6j3g2.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1657977179/Logos/Woodwork/Artboard-2-min_yxfrwd.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1657977179/Logos/Woodwork/Document_3_-min_ezsj3f.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1657977179/Logos/Woodwork/cropped-logo_fullcolor-01-min_fefnls.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1657977179/Logos/Woodwork/Barnes-Noble-Logo-min_wngia4.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1657977179/Logos/Woodwork/Deseret_Book_2010_logo-min_gvxwbu.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1659624103/Logos/Woodwork/DValleyLogo_green_horizontal_jka67a.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1659624086/Logos/Woodwork/identity_logo_square-min_dxgmqr.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1659624086/Logos/Woodwork/Microsoft_logo__2012_.svg-min_azhmbf.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1659624086/Logos/Woodwork/SmallLogo-5be087e192baa-min_noc6nx.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1659624085/Logos/Woodwork/wcsdBrand_allApprovedLogos-02-min_uud4sa.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1659624085/Logos/Woodwork/15894657_1668261483187669_4895921871040707236_n-min_ufexz8.webp",
        },
      ],
    };
  },

  methods: {
    openSocial(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.outside-container {
  position: relative;
}

#socials {
  position: absolute;
  top: 30%;
  left: 10px;
  z-index: 999;
  p {
    transform: rotate(90deg);
  }
}

video {
  min-width: 100vw;
  max-height: 99vh;
  object-fit: cover;
}

::v-deep #gradient {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 250px;
  background: linear-gradient(
    rgba(46, 46, 46, 0),
    var(--v-secondary-base)
  ) !important;
}
</style>
