<template>
  <div class="white--text px-10 pb-16">
    <h2 class="display-2 text-center py-16">{{ title }}</h2>
    <v-row>
      <v-col cols="12" md="10" class="mx-auto">
        <v-row v-for="team in teams" :key="team.i">
          <v-col cols="12" class="pb-0" v-if="lists[team.value].length > 0">
            <h3 class="font-weight-light headline primary--text">
              {{ team.name }}
            </h3>
          </v-col>
          <v-col
            v-for="item in lists[team.value]"
            :key="item.i"
            class="d-flex"
            :class="$vuetify.breakpoint.mdAndUp ? '' : 'px-1'"
            cols="6"
            md="auto"
          >
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card width="230" height="230">
                  <v-img :src="item.image.temp" height="100%">
                    <v-fade-transition>
                      <v-overlay v-if="hover" absolute color="primary darken-2">
                        <p class="headline text-center mb-0">
                          {{ item.name }}
                        </p>
                        <p class="title text-center font-wight-light">
                          {{ item.position }}
                        </p>
                      </v-overlay>
                    </v-fade-transition>
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        />
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </template>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { employeesCollection, storage } from "../../firebase/credentials";
export default {
  data() {
    return {
      employees: [],
      title: "Our Team",
      teams: [
        { name: "Executive", value: "executive" },
        { name: "Management", value: "management" },
        { name: "Supervisor", value: "supervisor" },
      ],
      groups: [
        {
          executive: [],
          management: [],
          supervisor: [],
        },
      ],
    };
  },
  methods: {
    list(ind) {
      let val = this.groups[0][ind];
      // let result = val.sort((a, b) => a.order - b.order);
      return val;
    },
  },
  computed: {
    lists() {
      const outer = this.groups[0];
      for (const item in outer) {
        outer[item] = outer[item].sort((a, b) => a.order - b.order);
      }
      return outer;
    },
  },
  props: {
    company: {
      type: String,
      default: "JLR Contractors",
    },
  },
  async mounted() {
    let result = await employeesCollection.where("company", "==", this.company);
    result.onSnapshot((a) => {
      this.employees = [];
      a.docChanges().forEach(async (p) => {
        if (p.type === "added") {
          let img;
          if (p.doc.data().image) {
            img = await storage
              .ref()
              .child(p.doc.data().image)
              .getDownloadURL();
          }
          let obj = p.doc.data();
          obj.id = p.doc.id;
          obj.image = {
            path: p.doc.data().image,
            temp: img,
          };
          if (obj.team != null && obj.team != undefined) {
            if (obj.team == "Executive") {
              this.groups[0].executive.push(obj);
            } else if (obj.team == "Management") {
              this.groups[0].management.push(obj);
            } else if (obj.team == "Supervisor") {
              this.groups[0].supervisor.push(obj);
            }
          }
        } else if (p.type === "removed") {
          this.employees.splice(p.doc.data().order, 1);
        }
      });
    });
  },
};
</script>
